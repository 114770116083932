<template lang="pug">
  v-card(v-if="!error")
    v-card-text
      v-tabs.mb-2(v-if="tipo_viaje === 'vuelta'" v-model="tab" color="secondary" slider-color="primary" slider-size="4")
        v-tab(v-for="(it, i) in itinerario", :key="i")
          | {{ tipo(i) }}
      v-tabs-items(v-model="tab")
        v-tab-item(v-for="(it, i) in itinerario", :key="i")
          v-card(v-if="it" outlined color="primary")
            v-card-text.px-md-16
              v-row(align="center" justify="space-between")
                v-col(cols="auto")
                  div.text-h6.white--text Detalle del viaje
                v-col.d-flex.align-center.text-body-1.font-weight-medium(cols="auto")
                  div.secondary--text Viaje de {{ tipo(i) }}:
                  div.ml-2.white--text {{ $moment(it.Fecha).format('dddd LL') }}
              v-row(align="center" justify="space-between")
                v-col.d-flex.align-center.text-body-1.font-weight-medium(cols="auto")
                  div.secondary--text Nave:
                  div.ml-2.white--text.text-capitalize {{ it.Nave.toLowerCase() }}
                v-col.d-flex.align-center.text-body-1.font-weight-medium(cols="auto")
                  div.secondary--text Zarpe:
                  div.ml-2.white--text.text-capitalize {{ origen.toLowerCase() }} {{ $moment(it.Fecha).format('HH:mm A') }}
                v-col.d-flex.align-center.text-body-1.font-weight-medium(cols="auto")
                  div.secondary--text Recalada:
                  div.ml-2.white--text.text-capitalize {{ destino.toLowerCase() }} {{ $moment(it.Fecha).add(it.Duracion, 'minutes').format('DD MMM') }} {{ $moment(it.Fecha).add(it.Duracion, 'minutes').format('HH:mm A') }}
          v-expansion-panels.mb-6(v-model="activo" style="z-index: 0" accordion)
            v-expansion-panel(v-for="(pasajero, p) in pasajeros", :key="'p' + p")
              v-expansion-panel-header
                v-row(justify="space-between" align="center" no-gutters)
                  v-col.d-flex(cols="auto")
                    v-icon.mr-2(color="secondary") mdi-account
                    div.primary--text.text-body-1.font-weight-medium Pasajero {{ p + 1 }}
                  v-col.mr-6.align-center.d-flex(cols="auto")
                    //div.primary--text.text-body-2 Subotal:
                    div.secondary--text.text-body-1.font-weight-medium(v-if="pasajero[tipo(i)]") {{ pasajero[tipo(i)].precio | currency('$ ', 0, { thousandsSeparator: '.' }) }}
              v-expansion-panel-content(v-if="pasajero")
                v-row.text-body-2(dense justify="space-around")
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Nombre:
                    div.ml-2 {{ pasajero.pName }} {{ pasajero.pLastname }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium {{ pasajero.pDocType }}:
                    div.ml-2 {{ pasajero.pDocNum }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Género:
                    div.ml-2 {{ pasajero.pGender }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Nacionalidad:
                    div.ml-2 {{ pasajero.pNationality }}
                v-row.text-body-2(dense justify="space-around")
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Fecha de Nac.:
                    div.ml-2 {{ pasajero.pBirthdate }}
                  v-col.d-flex.justify-center(v-if="pasajero[tipo(i)]" cols="12" sm="6" md="auto")
                    div.font-weight-medium Asiento {{ tipo(i) }}:
                    div.ml-2 {{ pasajero[ tipo(i) ].asiento }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Residente:
                    div.ml-2 No
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Descuento:
                    div.ml-2 No
            v-expansion-panel(v-for="(vehiculo, v) in vehiculos", :key="'v' + v")
              v-expansion-panel-header
                v-row(justify="space-between" align="center" no-gutters)
                  v-col.d-flex(cols="auto")
                    v-icon.mr-2(color="secondary") {{ getIcon(v) }}
                    div.primary--text.text-body-1.font-weight-medium Vehículo {{ v + 1 }}
                  v-col.mr-6.align-center.d-flex(cols="auto")
                    //div.primary--text.text-body-2 Subotal:
                    div.secondary--text.text-body-1.font-weight-medium(v-if="vehiculo[tipo(i)]") {{ vehiculo[tipo(i)].precio | currency('$ ', 0, { thousandsSeparator: '.' }) }}
              v-expansion-panel-content(v-if="vehiculo")
                v-row.text-body-2(dense justify="space-around")
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Marca:
                    div.ml-2 {{ vehiculo.marca }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Modelo:
                    div.ml-2 {{ vehiculo.modelo }}
                v-row.text-body-2(dense justify="space-around")
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Patente:
                    div.ml-2 {{ vehiculo.patente }}
                  v-col.d-flex.justify-center(cols="12" sm="6" md="auto")
                    div.font-weight-medium Conductor:
                    div.ml-2 {{ vehiculo.conductor }}
      WidgetDTE(ref="WidgetDTE", :p="pasajeros.length", :v="vehiculos.length")
      WidgetContacto.mt-6(ref="WidgetContacto")
      // WidgetSocios.mt-6
      v-row.mt-6(justify="center")
        v-spacer(v-if="$vuetify.breakpoint.mdAndDown")
        v-col(cols="auto")
          div.text-body-1.font-weight-medium.mb-2 Total a pagar:
          div.text-h3.primary--text {{ total | currency('$ ', 0, { thousandsSeparator: '.' }) }}
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3.pagar_sf(color="secondary" @click="pagar" :disabled="loadingCodigo" :loading="loading")
        | ir a pagar
        v-icon(right) mdi-chevron-right
  v-card(v-else)
    v-card-title Ha ocurrido un error
    v-card-text
      v-row
        v-col
          div.my-3.body-1 Lo sentimos. No pudimos procesar su reserva.
          div.my-3.body-1 Revise sus datos e intente nuevamente.
          div.my-3.body-1 Si el problema persiste, comuníquese con su agencia más cercana.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
</template>

<script>

import WidgetDTE from '@/components/UI/WidgetDTE'
import WidgetContacto from '@/components/UI/WidgetContacto'
import WidgetSocios from '@/components/UI/WidgetSocios'

export default {
  name: 'Detalle',

  components: {
    WidgetDTE,
    WidgetContacto,
    WidgetSocios
  },

  data: () => ({
    tab: 0,
    activo: null,
    pagarLoading: false
  }),

  watch: {
  },

  computed: {
    itinerario () {
      return this.$store.state.itinerario
    },

    tipo_viaje () {
      return this.$store.state.tipo_viaje
    },

    origen () {
      if (this.tab === 0) {
        return this.$store.state.origen
      } else {
        return this.$store.state.destino
      }
    },

    destino () {
      if (this.tab === 0) {
        return this.$store.state.destino
      } else {
        return this.$store.state.origen
      }
    },

    pasajeros () {
      return this.$store.state.pasajerosArr
    },

    vehiculos () {
      return this.$store.state.vehiculosArr
    },

    total () {
      if (this.$store.state.tarifas) {
        return this.$store.state.tarifas.total
      } else {
        return 0
      }
    },

    error () {
      if (this.$store.state.tarifas) {
        return this.$store.state.tarifas.error_tarifa || this.total === 0
      } else {
        return false
      }
    },

    loading () {
      return this.$store.state.datosPagoLoading || this.pagarLoading
    },

    loadingCodigo () {
      return this.$store.state.codigoLoading
    }
  },

  methods: {
    tipo (t) {
      if (t === 1) {
        return 'vuelta'
      } else {
        return 'ida'
      }
    },

    getIcon (n) {
      switch(this.vehiculos[n].tipo) {
        case 'menor':
          return 'mdi-car'
        //case 'bus':
          //return this.getSubIcon(n)
        case 'moto':
          return 'mdi-motorbike'
        case 'bici':
          return 'mdi-bicycle'
      }
      return 'mdi-help'
    },

    async pagar () {
      const v1 = this.$refs['WidgetDTE'].validar()
      const v2 = this.$refs['WidgetContacto'].validar()

      if (v1 && v2) {
        const datos = await this.$store.dispatch('agregar_datos_pago')
        if (!datos) {
          this.$store.dispatch('notify', {
            color: 'error',
            text: 'Hay un error en los datos de contacto. Intente nuevamente',
            icon: 'mdi-alert-circle',
            timeout: 5000
          })
          this.pagarLoading = false
          return
        }
        this.pagarLoading = true
        await this.$store.dispatch('pagar')
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    }
  }
}

</script>

<style type="text/css">

.pagar_sf span {
  pointer-events: none;
}

</style>
