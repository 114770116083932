<template lang="pug">
  v-card(v-if="!loading")
    v-card-text(v-if="!error")
      ResumenLink.mb-6(:tarifas="tarifas" :id_reserva="reserva")
      WidgetDTE.mb-6(ref="WidgetDTE" :p="p" :v="v")
      WidgetContactoLink.mb-6(ref="WidgetContacto")
      // WidgetSocios
      v-row.mt-6(justify="center")
        v-spacer(v-if="$vuetify.breakpoint.mdAndDown")
        v-col(cols="auto")
          div.text-body-1.font-weight-medium.mb-2 Total a pagar:
          div.text-h3.primary--text {{ total | currency('$ ', 0, { thousandsSeparator: '.' }) }}

    v-card-text(v-else)
      div.text-body-1 Este link ya no está disponible

    v-card-actions
      v-spacer
      v-btn.mx-3.mb-3.pagar_sf(v-if="!error" color="secondary" @click="pagar" :disabled="loadingCodigo" :loading="pagarLoading")
        | ir a pagar
        v-icon(right) mdi-chevron-right
      v-btn.mx-3.mb-3(v-else color="secondary" href="/")
        | volver al inicio
        v-icon(right) mdi-home

  v-card(v-else)
    v-card-text
      v-progress-linear(indeterminate)
</template>

<script>

import WidgetDTE from '@/components/UI/WidgetDTE'
import WidgetContactoLink from '@/components/UI/WidgetContactoLink'
import ResumenLink from '@/components/UI/ResumenLink'
import WidgetSocios from '@/components/UI/WidgetSocios'

export default {
  name: 'LinkPagoComponent',

  components: {
    ResumenLink,
    WidgetDTE,
    WidgetContactoLink,
    WidgetSocios
  },

  data: () => ({
    id: undefined,
    id_reserva: undefined,
    reserva: undefined,
    tarifas: {},
    pagarLoading: false,
    error: false
  }),

  watch: {
  },

  computed: {
    loadingCodigo () {
      return this.$store.state.codigoLoading
    },

    loading () {
      return this.$store.state.tarifasLoading
    },

    total () {
      if (this.$store.state.tarifas) {
        return this.$store.state.tarifas.total
      } else {
        return 0
      }
    },

    p () {
      if (this.$store.state.tarifas && this.$store.state.tarifas.reserva && this.$store.state.tarifas.reserva.pasajeros) {
        return this.$store.state.tarifas.reserva.pasajeros.length
      } else {
        return 0
      }
    },

    v () {
      if (this.$store.state.tarifas && this.$store.state.tarifas.reserva && this.$store.state.tarifas.reserva.vehiculos) {
        return this.$store.state.tarifas.reserva.vehiculos.length
      } else {
        return 0
      }
    }
  },

  async mounted () {
    this.verificar_query()
    const datos = {
      id: this.id,
      id_reserva: this.id_reserva
    }
    if (this.reserva) {
      datos.reserva = this.reserva
    }
    this.tarifas = await this.$store.dispatch('getTarifas', datos).catch(() => {
      this.$store.dispatch('notifyErrorServer')
    })

    if (this.tarifas === undefined) {
      this.error = true
    }
  },

  methods: {
    verificar_query() {
      const q = {...this.$route.query}

      // Limpiar URL
      if(Object.keys(q).length > 0) {
        this.$router.push('/pago')
      }

      if (!q.id || !this.check_id(q.id)) {
        // UUID no válido
        this.$router.push('/')
        return
      }

      if (!q.id_reserva || !this.check_id_reserva(q.id_reserva)) {
        // ID Reserva no válido
        this.$router.push('/')
        return
      }

      if (q.Reserva && /R-[0-9]{1,12}/.test(q.Reserva)) {
        this.reserva = q.Reserva.split('R-')[1]
      }

      this.id = q.id
      this.id_reserva = q.id_reserva
    },

    async pagar () {
      const v1 = this.$refs['WidgetDTE'].validar()
      const v2 = this.$refs['WidgetContacto'].validar()

      if (v1 && v2) {
        this.pagarLoading = true
        const datos = await this.$store.dispatch('agregar_datos_pago')
        if (!datos) {
          this.$store.dispatch('notify', {
            color: 'error',
            text: 'Hay un error en los datos de contacto. Intente nuevamente',
            icon: 'mdi-alert-circle',
            timeout: 5000
          })
          this.pagarLoading = false
          return
        }
        await this.$store.dispatch('pagar')
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    }
  }
}

</script>

<style type="text/css">

</style>
