<template lang="pug">
  Butacas(@next="next" @prev="prev")
</template>

<script>

import Butacas from '@/components/UI/Butacas'

export default {
  name: 'Paso2',

  components: {
    Butacas
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
    prev () {
      this.$store.commit('SET_PASO', 1)
      this.$router.push('/paso1')
    },

    next () {
      this.$store.commit('SET_PASO', 3)
      this.$router.push('/paso3')
    }
  }
}

</script>

<style type="text/css">

</style>
