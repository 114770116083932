<template lang="pug">
  v-row(no-gutters)
    v-col(v-for="(item, i) in items", :key="i" v-bind:class="clases(i)")
      div.redondeado.d-flex.align-center.white--text(v-bind:class=" i < paso ? 'secondary clickable' : 'grey lighten-1'" @click="click(item, i)")
        div.text-h6.mx-3 {{ i + 1 }}
        div.text-body-1 {{ item.titulo }}
        v-spacer
        div.circle.primary
          v-icon.fill-height(color="white" large) mdi-chevron-right
</template>

<script>

export default {
  name: 'Breadcrumbs',

  components: {
  },

  data: () => ({
    items: [
      {
        titulo: 'Fecha de viaje',
        path: 'Paso1'
      },
      {
        titulo: 'Selección de asientos',
        path: 'Paso2'
      },
      {
        titulo: 'Pasajeros y vehículos',
        path: 'Paso3'
      },
      {
        titulo: 'Detalle del viaje',
        path: 'Paso4'
      }
    ]
  }),

  watch: {
  },

  computed: {
    paso () {
      return this.$store.state.paso
    }
  },

  methods: {
    click (item, i) {
      if (i < this.paso && this.$route.name !== item.path) {
        this.$store.commit('SET_PASO', i + 1)
        this.$router.push({
          name: item.path
        })
      }
    },

    clases (i) {
      let clases = ''
      if (i < this.paso - 1) {
        clases = 'secondary'
      } else {
        clases = 'grey lighten-1'
      }

      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          if (i !== this.paso - 1) {
            clases += ' d-none'
          }
          break
        case 'sm':
          if (i !== this.paso - 1 && i !== this.paso) {
            clases += ' d-none'
          }
          break
        case 'md':
          if (this.paso >= 3) {
            if (i !== this.paso - 2 && i !== this.paso - 1 && i !== this.paso) {
              clases += ' d-none'
            }
          } else {
            if (i !== this.paso - 1 && i !== this.paso && i !== this.paso + 1) {
              clases += ' d-none'
            }
          }
          break
        case 'lg':
        case 'xl':
      }

      return clases
    }
  }
}

</script>

<style type="text/css">
  .redondeado {
    border-radius: 0px 25px 25px 0px;
  }

  .clickable {
    cursor: pointer;
  }

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align-last: center;
  }

  a.fill-div {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
  }

</style>
