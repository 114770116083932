import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('Notify'),_c(VAppBar,{attrs:{"color":"primary","dark":"","app":""}},[_c(VSpacer,{staticClass:"hidden-sm-and-down"}),_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{staticClass:"shrink",attrs:{"alt":"Logo","contain":"","src":"images/logo.svg","height":"52","max-width":"130"}})],1),_c(VSpacer),_c(VSlideXReverseTransition,[(_vm.mostrar_boton)?_c(VBtn,{staticClass:"hidden-md-and-up",attrs:{"text":""},on:{"click":function($event){_vm.mostrar_resumen = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-playlist-edit")]),_vm._v("Reserva")],1):_vm._e()],1),(_vm.timer)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VProgressCircular,_vm._g(_vm._b({staticClass:"hidden-md-and-up",attrs:{"size":"24","value":_vm.porcentaje,"rotate":"-90","width":"2"}},'v-progress-circular',attrs,false),on),[_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.minutos))])])]}}],null,false,3404051999)},[_c('span',[_vm._v("Minutos restantes")])]):_vm._e()],1),(_vm.$route.path.startsWith('/paso'))?_c('Breadcrumbs',{staticClass:"flotante",class:_vm.$vuetify.breakpoint.smAndDown ? 't56' : 't64'}):_vm._e(),_c(VMain,{staticClass:"grey lighten-3 mt-12"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8","xl":"6"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),(_vm.esconder_resumen())?_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"cols":"4","lg":"3"}},[_c('Resumen',{staticClass:"fixed-resumen",on:{"mostrar":function($event){_vm.mostrar_boton = true}}})],1):_vm._e()],1)],1)],1),_c(VDialog,{attrs:{"transition":"dialog-top-transition"},model:{value:(_vm.mostrar_resumen),callback:function ($$v) {_vm.mostrar_resumen=$$v},expression:"mostrar_resumen"}},[_c('Resumen',{on:{"cerrar":function($event){_vm.mostrar_resumen = false}}})],1),_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.timeover),callback:function ($$v) {_vm.timeover=$$v},expression:"timeover"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Tiempo agotado")]),_c(VCardText,[_c('div',{staticClass:"text-body-1"},[_vm._v("Debe crear una nueva reserva.")]),_c('div',{staticClass:"text-body-1"},[_vm._v("Recuerde que tiene "),_c('b',[_vm._v("20 minutos")]),_vm._v(" para finalizar la compra.")]),_c(VImg,{attrs:{"src":"images/ferry.png","contain":"","height":"120"}}),_c('div',{staticClass:"font-italic text-body-2 text-right"},[_vm._v("Y se marchó... y a su barco le llamó libertad.")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.resetear}},[_vm._v("¡Entendido!")])],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }