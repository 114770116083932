import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSnackbar,{attrs:{"color":_vm.$store.state.notify_color,"timeout":_vm.$store.state.notify_timeout,"app":"","top":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v(_vm._s(_vm.$store.state.notify_icon))])],1),_c(VCol,{staticClass:"ml-2"},[_c('div',[_vm._v(_vm._s(_vm.$store.state.notify_text))])])],1),(_vm.$store.state.notify_timeout <= 0)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.$store.state.notify_flag = false}}},[_vm._v("OK")])],1)],1):_c(VProgressLinear,{staticClass:"mt-2",attrs:{"buffer-value":_vm.$store.state.notify_progress,"color":"white","height":"3"}})]},proxy:true}]),model:{value:(_vm.$store.state.notify_flag),callback:function ($$v) {_vm.$set(_vm.$store.state, "notify_flag", $$v)},expression:"$store.state.notify_flag"}})}
var staticRenderFns = []

export { render, staticRenderFns }