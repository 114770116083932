<template lang="pug">
  v-card
    v-card-text.text-center.justify-center
      v-icon(color="secondary" large) mdi-card-account-details-outline
      div.text-body-1.font-weight-medium.mb-3 Datos de contacto
      v-row.pb-6(no-gutters justify="center")
        v-col(cols="12" md="8")
          div.text-body-1.primary--text En caso de que exista reprogramaciones en horario de zarpe se avisará al contacto correspondiente vía sms, WhatsApp y correo.
      v-form(ref="form_rut")
        v-row(align="start")
          v-col(cols="12" sm="6")
            v-select(label="Identificación" v-model="pDocType", :items="ids" prepend-icon="mdi-account-box" color="secondary" outlined dense)
          v-col(cols="12" sm="6")
            v-text-field(ref="pax_form_rut" v-model="pDocNum", :label="pDocType" prepend-icon="mdi-account-box" color="secondary", :append-outer-icon="iconrut()" :loading="loading", :disabled="loading" required :rules="rules(true, pDocType)" outlined dense @click:append-outer="buscar" v-on:keyup="enter" @blur="on_blur_pax")
      v-form(ref="datos_contacto")
        v-row(align="start")
          v-col(cols="12" sm="6")
            v-text-field(v-model="pName" label="Nombres" prepend-icon="mdi-account" color="secondary" :disabled="disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur()" outlined dense)
          v-col(cols="12" sm="6")
            v-text-field(v-model="pLastname" label="Apellidos" prepend-icon="mdi-account" color="secondary" :disabled="disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur()" outlined dense)
          v-col(cols="12" sm="6")
            v-text-field(v-model="correo" label="Correo" outlined dense prepend-icon="mdi-email" color="secondary" required :rules="rules(true, 'correo')" :disabled="disabled")
          v-col(cols="12" sm="6")
            v-text-field(v-model="telefono" label="Teléfono" outlined dense prepend-icon="mdi-cellphone" color="secondary" required :rules="rules(!sin_telefono, 'telefono')", :disabled="sin_telefono || disabled" type="text" prefix="+56" v-mask="'#########'")
          v-col.py-0(v-if="pDocType === 'Pasaporte'" cols="12" sm="6")
            v-checkbox(v-model="sin_telefono" label="No tengo teléfono chileno" color="secondary" @change="onChangeTel" :disabled="disabled" hide-details)
          v-col(cols="12")
            v-expand-transition
              div.text-body-2.text-sm-body-1.font-italic.red--text(v-if="sin_telefono") En caso de suspensión o reprogramación la información se enviará al <b>correo electrónico</b>.
</template>

<script>

import { format } from 'rut.js'

export default {
  name: 'WidgetContactoLink',

  components: {
  },

  data: () => ({
    correo: '',
    telefono: '',
    pDocType: 'RUT',
    pDocNum: '',
    pName: '',
    pLastname: '',
    sin_telefono: false,
    buscado: false,
    disabled: true,
    loading: false,
    first_time: true,
    ids: ['RUT', 'Pasaporte'],
  }),

  watch: {
  },

  computed: {
  },

  mounted () {
  },

  methods: {
    async buscar () {
      if (this.$refs['form_rut'].validate()) {
        if (!this.buscado) {
          if (this.pDocType === 'RUT') {
            this.pDocNum = format(this.pDocNum, { dots: false })
          } else {
            this.pDocNum = this.pDocNum.toUpperCase().replace(/\s+/g, '')
          }
          this.loading = true
          const cliente = await this.$store.dispatch('getClienteLink', this.pDocNum)
          this.loading = false
          this.buscado = true
          this.disabled = false
          this.completar(cliente)
        } else {
          this.limpiar()
        }
      } else {
        //this.$store.state.pasajerosArr[p].form_rut = false
        this.$refs['pax_form_rut'].blur()
      }
    },

    completar (cliente) {
      if (!cliente) {
        return
      }

      this.pName = cliente.Nombre
      this.pLastname = cliente.Apellido
    },

    on_blur_pax () {
      // this.on_blur(p)
      this.buscar()
    },

    enter (e) {
      if (e.keyCode === 13) {
        this.buscar()
      } else {
        this.limpiar()
      }
    },

    limpiar (force) {
      if (force || this.buscado) {
        this.$refs['form_rut'].resetValidation()
        this.$refs['datos_contacto'].resetValidation()
        this.correo = ''
        this.telefono = ''
        this.pDocType = 'RUT'
        this.pDocNum = ''
        this.pName = ''
        this.pLastname = ''
        this.sin_telefono = false
        this.buscado = false
        this.disabled = true
        this.loading = false
        this.first_time = true
        this.$forceUpdate()
      }
    },

    iconrut () {
      if (this.buscado) {
        return 'mdi-close'
      } else {
        return 'mdi-magnify'
      }
    },

    onChangeTel () {
      this.telefono = ''
    },

    validar () {
      const v1 = this.$refs['form_rut'].validate()
      const v2 = this.$refs['datos_contacto'].validate()
      if (v1 && v2) {
        let telefono = this.telefono
        if (this.pDocType === 'Pasaporte' && this.sin_telefono) {
          telefono = '999999999'
        }
        const datos = {
          rut: this.pDocNum,
          nombre: this.pName,
          apellido: this.pLastname,
          correo: this.correo,
          telefono: '+56' + telefono
        }
        this.$store.commit('SET_DATOS_CONTACTO', datos)
      } else {
        this.$store.commit('SET_DATOS_CONTACTO', {})
      }
      return v1 && v2
    }
  }
}

</script>

<style type="text/css">

</style>
