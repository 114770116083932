<template lang="pug">
  v-card(outlined)
    v-card-title Pasajeros
    v-card-text
      div.mb-6.text-h6.font-weight-light Complete los datos de los pasajeros:
      v-expansion-panels(v-model="activo" style="z-index: 0")
        v-expansion-panel(v-for="(pasajero, p) in pasajeros", :key="p")
          v-expansion-panel-header(:disable-icon-rotate="tiene_error(p) !== 0")
            v-row
              v-col.d-flex(cols="auto")
                v-icon.mr-2(color="secondary") mdi-account
                div.primary--text.text-body-1.font-weight-medium Pasajero {{ p + 1 }}
            template(v-if="tiene_error(p) === 0" v-slot:actions)
              v-icon $expand
            template(v-else-if="tiene_error(p) === 1" v-slot:actions)
              v-icon(color="error") mdi-alert-circle
            template(v-else v-slot:actions)
              v-icon(color="success") mdi-check
          v-expansion-panel-content
            v-form(:ref="'form_rut_' + p" v-model="pasajero.form_rut")
              v-row.mb-3(align="center" dense)
                v-col.d-flex(cols="12" sm="6")
                  v-icon.mr-2(color="secondary") mdi-seat
                  div.text-body-1.primary--text Nro. {{ pasajero.ida.asiento }} (ida)
                v-col.d-flex(v-if="tipo === 'vuelta'" cols="12" sm="6")
                  v-icon.mr-2(color="secondary") mdi-seat
                  div.text-body-1.primary--text Nro. {{ pasajero.vuelta.asiento }} (vuelta)
              v-row.my-0(align="center" dense)
                v-col(cols="12" sm="6")
                  v-select(label="Identificación" v-model="pDocType", :items="ids" prepend-icon="mdi-account-box" color="secondary" filled)
                v-col(cols="12" sm="6" dense)
                  v-text-field(ref="pax_form_rut" v-model="pasajero.pDocNum", :label="pasajero.pDocType" prepend-icon="mdi-account-box" color="secondary", :append-outer-icon="iconrut(p)" filled @click:append-outer="buscar(p)" v-on:keyup="enter(p, $event)", :loading="pasajero.loading", :disabled="pasajero.loading" required, :rules="rules(true, pasajero.pDocType)", :validate-on-blur="!pasajero.onblur && first_time" @blur="on_blur_pax(p)")
            v-form(:ref="'form_pasajeros_' + p")
              v-row.my-0(align="center" dense)
                v-col(cols="12" sm="6")
                  v-text-field(v-model="pasajero.pName" label="Nombres" prepend-icon="mdi-account" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
                v-col(cols="12" sm="6")
                  v-text-field(v-model="pasajero.pLastname" label="Apellidos" prepend-icon="mdi-account" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
              v-row.my-0(align="center" dense)
                v-col(cols="12" sm="6")
                  v-select(label="Género" v-model="pasajero.pGender", :items="genero" prepend-icon="mdi-human-male-female" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
                v-col(cols="12" sm="6" v-if="activo === p")
                  v-menu(v-model="menu_birthday" :ref="'menu_' + p" offset-y transition="scale-transition", :nudge-right="30" min-width="290px", :close-on-content-click="false")
                    template(v-slot:activator="{ on, attrs }")
                      v-text-field(label="Fecha de nacimiento" v-on="on" v-bind="attrs" readonly v-model="pasajero.pBirthdate" prepend-icon="mdi-calendar" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
                    v-date-picker(v-model="pasajero.pBirthdate", :active-picker.sync="activePicker", :max="today", :min="today_100" locale="es" @change="save(pasajero.pBirthdate, p)" color="secondary")
              v-row.my-0(align="center" dense)
                v-col(cols="12" sm="6")
                  v-text-field(v-model="pasajero.pEmail" label="Correo electrónico" prepend-icon="mdi-email" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true, 'correo')", :validate-on-blur="first_time" @blur="on_blur(p)")
                v-col(cols="12" sm="6")
                  v-autocomplete(label="País de origen" v-model="pasajero.pais", :items="paises" item-text="name" item-value="iso" prepend-icon="mdi-flag" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
                  // v-select(label="Nacionalidad" v-model="pasajero.pNationality", :items="nacionalidades" prepend-icon="mdi-flag" color="secondary" filled, :disabled="pasajero.disabled" required, :rules="rules(true)", :validate-on-blur="first_time" @blur="on_blur(p)")
              v-row.my-0(align="start" dense)
                v-col(cols="12" sm="6")
                  v-text-field(v-model="pasajero.pPhone" label="Teléfono" prepend-icon="mdi-cellphone" color="secondary" filled, :disabled="pasajero.disabled || pasajero.sin_telefono" required, :rules="rules(!pasajero.sin_telefono, 'telefono')" type="text" prefix="+56" v-mask="'#########'", :validate-on-blur="first_time" @blur="on_blur(p)")
                v-col(v-if="pasajero.pDocType === 'Pasaporte'" cols="12" sm="6")
                  v-checkbox(v-model="pasajero.sin_telefono" label="No tengo teléfono chileno" :disabled="pasajero.disabled" color="secondary" @change="onChangeTel(p)")
                v-col(cols="12")
                  v-expand-transition
                    div.text-body-2.text-sm-body-1.font-italic.red--text(v-if="pasajero.sin_telefono") En caso de suspensión o reprogramación la información se enviará al <b>correo electrónico</b>.
</template>

<script>

import { format, validate } from 'rut.js'
import paises from 'iso3166-2-db/countryList/es';

export default {
  name: 'WidgetPasajeros',

  components: {
  },

  data: () => ({
    today: '',
    today_100: '',
    activo: 0,
    first_time: true,
    activePicker: 'YEAR',
    menu_birthday: false,
    ids: ['RUT', 'Pasaporte'],
    genero: ['Femenino', 'Masculino'],
    paises: []
  }),

  watch: {
    menu_birthday (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },

    activo (val) {
      this.menu_birthday = false
      if (val && !this.first_time) {
        setTimeout(() => {
          this.validacion_form_manual(val)
        },
        100)
      }
    }
  },

  computed: {
    pasajeros () {
      return this.$store.state.pasajerosArr
    },

    tipo () {
      return this.$store.state.tipo_viaje
    },

    pDocType: {
      get () {
        if (this.activo === undefined) {
          return ''
        }
        return this.pasajeros[this.activo].pDocType
      },

      set (val) {
        if (this.activo === undefined) {
          return
        }
        this.limpiar(this.activo, true)
        this.pasajeros[this.activo].pDocType = val
      }
    },

    rut_label () {
      return this.pDocType
    },

    nacionalidades () {
      return this.$store.state.nacionalidades
    }
  },

  mounted () {
    this.today = this.$moment().format('YYYY-MM-DD')
    this.today_100 = this.$moment().subtract(100,'years').format('YYYY-MM-DD')
    // this.$store.dispatch('getNacionalidades')
    this.paises = Object.keys(paises).map(k => paises[k])
  },

  methods: {
    async buscar (p) {
      if (this.$refs['form_rut_' + p][0].validate()) {
        if (!this.$store.state.pasajerosArr[p].buscado) {
          if (this.$store.state.pasajerosArr[p].pDocType === 'RUT') {
            this.$store.state.pasajerosArr[p].pDocNum = format(this.$store.state.pasajerosArr[p].pDocNum, { dots: false })
          } else {
            this.$store.state.pasajerosArr[p].pDocNum = this.$store.state.pasajerosArr[p].pDocNum.toUpperCase().replace(/\s+/g, '')
          }
          this.$store.state.pasajerosArr[p].loading = true
          this.$forceUpdate()
          await this.$store.dispatch('getCliente', p)
          this.$forceUpdate()
        } else {
          this.limpiar(p)
        }
      } else {
        //this.$store.state.pasajerosArr[p].form_rut = false
        this.$refs['pax_form_rut'][p].blur()
        this.$store.state.pasajerosArr[p].onblur = true
        this.$forceUpdate()
      }
    },

    enter (p, e) {
      if (e.keyCode === 13) {
        this.buscar(p)
      } else {
        this.limpiar(p)
      }
    },

    save (date, p) {
      this.$refs['menu_' + p][0].save(date)
    },

    limpiar (p, force) {
      if (force || this.pasajeros[p].buscado) {
        this.$refs['form_rut_' + p][0].resetValidation()
        this.$refs['form_pasajeros_' + p][0].resetValidation()
        this.$store.commit('LIMPIAR_PASAJERO', p)
        this.$forceUpdate()
      }
    },

    iconrut (p) {
      if (this.$store.state.pasajerosArr[p].buscado) {
        return 'mdi-close'
      } else {
        return 'mdi-magnify'
      }
    },

    validar () {
      this.first_time = false
      let validos = []
      for (let p = 0; p < this.pasajeros.length; p++) {
        if (p === this.activo) {
          validos.push(this.validacion_form_manual(p))
        } else {
          validos.push(this.validacion_manual(p))
        }
      }
      this.$forceUpdate()
      return validos.reduce((a, b) => a && b)
    },

    validacion_form_manual (p) {
      const v1 = this.$refs['form_rut_' + p]
      const v2 = this.$refs['form_pasajeros_' + p]
      if (v1 && v2) {
        const valido = v1[0].validate() && v2[0].validate()
        this.pasajeros[p].error = !valido
        return valido
      }
      this.pasajeros[p].error = true
      return false
    },

    validacion_manual (p) {
      const pasajero = this.pasajeros[p]

      // Validar rut
      if (pasajero.pDocType === 'RUT') {
        let v = validate(pasajero.pDocNum)
        if (!v) {
          pasajero.error = true
          return false
        }
      }

      if (!pasajero.buscado) {
        pasajero.error = true
        return false
      }

      if (pasajero.pName === null) {
        pasajero.error = true
        return false
      }

      if (pasajero.pLastname === null) {
        pasajero.error = true
        return false
      }

      if (pasajero.pGender === null) {
        pasajero.error = true
        return false
      }

      if (pasajero.pBirthdate === null) {
        pasajero.error = true
        return false
      }

      if (!pasajero.pEmail) {
        pasajero.error = true
        return false
      }

      if (!pasajero.pPhone && !pasajero.sin_telefono) {
        pasajero.error = true
        return false
      }
      pasajero.error = false
      return true
    },

    tiene_error (p) {
      if (this.first_time) {
        return 0
      }
      if (this.pasajeros[p].error) {
        return 1
      }
      return 2
    },

    on_blur (p) {
      if (this.first_time) {
        return
      }
      this.validacion_form_manual(p)
      this.$forceUpdate()
    },

    on_blur_pax (p) {
      // this.on_blur(p)
      this.buscar(p)
    },

    async onChangeTel (p) {
      this.$store.state.pasajerosArr[p].pPhone = ''
      let data = {
        index: p,
        data: this.$store.state.pasajerosArr[p]
      }

      await this.$store.commit('SET_PASAJERO', data)

      this.$forceUpdate()
    }
  }
}

</script>

<style type="text/css">

</style>
