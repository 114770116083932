<template lang="pug">
  v-card(v-if="!error")
    v-card-text
      WidgetPasajeros(ref="wPasajeros" v-if="pasajeros > 0")
          // v-card.mb-4(outlined v-for="(pasajero, p) in pasajeros")
            v-card-text
              v-row
                v-col.d-flex(cols="auto")
                  v-icon(color="secondary") mdi-account
                  div.primary--text.text-h6 Pasajero {{ p + 1 }}
                v-col.d-flex
                  v-icon(color="secondary") mdi-seat
                  div.primary--text.text-h6 Nro. 70
              v-row(align="center")
                v-col(cols="6")
                  v-radio-group(hide-details)
                    v-radio(label="Rut" color="secondary")
                    v-radio(label="Pasaporte" color="secondary")
                v-col(cols="6")
                  v-text-field(label="Rut" prepend-icon="mdi-account-box" color="secondary")
              v-row(align="center")
                v-col
                  v-text-field(label="Nombres" prepend-icon="mdi-account" color="secondary")
                v-col
                  v-text-field(label="Apellidos" prepend-icon="mdi-account" color="secondary")
              v-row(align="center")
                v-col
                  v-radio-group(hide-details)
                    v-radio(label="Masculino" color="secondary")
                    v-radio(label="Femenino" color="secondary")
                v-col
                  v-text-field(label="Fecha de nacimiento" prepend-icon="mdi-calendar" color="secondary")
      WidgetVehiculos.mt-3(ref="wVehiculos" v-if="vehiculos > 0")

    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3(color="secondary" @click="validar()", :loading="loading")
        | Continuar
        v-icon(right) mdi-chevron-right

  v-card(v-else)
    v-card-title Ocurrió un error
    v-card-text
      v-row
        v-col
          div.my-3.body-1 Lo sentimos, no pudimos generar su reserva.
          div.my-3.body-1 Por favor revise sus datos e intente nuevamente.
          div.my-3.body-1 Si el problema persiste, comuníquese con su agencia más cercana.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
</template>

<script>

import WidgetPasajeros from '@/components/UI/WidgetPasajeros'
import WidgetVehiculos from '@/components/UI/WidgetVehiculos'

export default {
  name: 'PasajerosVehiculos',

  components: {
    WidgetPasajeros,
    WidgetVehiculos
  },

  data: () => ({
    WidgetPasajeros
  }),

  watch: {
  },

  computed: {
    pasajeros () {
      return this.$store.state.pasajerosArr ? this.$store.state.pasajerosArr.length : 0
    },

    vehiculos () {
      return this.$store.state.vehiculosArr ? this.$store.state.vehiculosArr.length : 0
    },

    loading () {
      return this.$store.state.nominaPaxLoading || this.$store.state.nominaVehLoading || this.$store.state.tarifasLoading
    },

    error () {
      return this.$store.state.prereserva === null
    }
  },

  methods: {
    async validar () {
      const v1 = this.$refs['wPasajeros'].validar()
      const v2 = this.vehiculos > 0 ? this.$refs['wVehiculos'].validar() : true

      if (v1 && v2) {
        const nominas = []
        const nP = await this.$store.dispatch('nominar_pasajeros')
        nominas.push(nP)
        if (this.vehiculos > 0) {
          const nV = await this.$store.dispatch('nominar_vehiculos')
          nominas.push(nV)
        }
        let flag = true
        for (let n = 0; n < nominas.length; n++) {
          flag = flag && nominas[n]
        }
        if (flag) {
          await this.$store.dispatch('getTarifas')
          this.$emit('next')
        } else {
          this.$store.dispatch('notify', {
            color: 'error',
            text: 'Error al nominar pasajeros y/o vehículos',
            icon: 'mdi-alert-circle'
          })
        }
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    }
  }
}

</script>

<style type="text/css">

</style>
