<template lang="pug">
  v-card(v-if="tarifas && tarifas.reserva")
    v-card-text.text-center.justify-center
      v-icon(color="secondary" large) mdi-card-account-details
      div.text-body-1.font-weight-medium.mb-3 Resumen Reserva {{ nombre_reserva }}
      v-row(justify="center")
        v-col(v-for="pax, p in tarifas.reserva.pasajeros" :key="p" cols="12" sm="6")
          v-card
            v-card-title Pasajero {{ p + 1 }}
            v-card-text
              div <b>Nombre</b>: {{ pax.nombre | capitalize}}
              div <b>RUT</b>: {{ rutificar(pax.identificacion) }}
              div <b>Butaca</b>: {{ pax.numero || 'S/A' }}
              div <b>Precio</b>: {{ pax.pasaje | currency('$ ', 0, { thousandsSeparator: '.' }) }}
      v-row(justify="center")
        v-col(v-for="veh, v in tarifas.reserva.vehiculos" :key="v" cols="12" sm="6")
          v-card
            v-card-title Vehículo {{ v + 1 }}
            v-card-text
              div <b>Patente:</b> {{ patente(veh.patente) }}
              div <b>Categoría:</b> {{ veh.categoria | capitalize }}
              div <b>Tipo:</b> {{ veh.tipo | capitalize }}
              div <b>Largo:</b> {{ veh.metros }} metros
              div <b>Precio:</b> {{ veh.pasaje | currency('$ ', 0, { thousandsSeparator: '.' }) }}
</template>

<script>

import { format, validate } from 'rut.js'

export default {
  name: 'ResumenLinkComponent',

  props: {
    tarifas: {
      type: Object,
      default: () => {}
    },

    id_reserva: {
      type: String,
      default: () => undefined
    }
  },
  components: {
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    nombre_reserva () {
      if (!this.id_reserva) {
        return ''
      }

      return 'R-' + this.id_reserva
    }
  },

  methods: {
    patente (p) {
      if (!p || p === 'null') {
        return 'N/A'
      }
      return p.toUpperCase()
    },

    rutificar (rut) {
      if (!rut) {
        return 'N/A'
      }

      if (validate(rut)) {
        return format(rut)
      }

      return rut.toUpperCase()
    }
  }
}

</script>

<style type="text/css">

</style>
