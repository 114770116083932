import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c(VCard,[(!_vm.error)?_c(VCardText,[_c('ResumenLink',{staticClass:"mb-6",attrs:{"tarifas":_vm.tarifas,"id_reserva":_vm.reserva}}),_c('WidgetDTE',{ref:"WidgetDTE",staticClass:"mb-6",attrs:{"p":_vm.p,"v":_vm.v}}),_c('WidgetContactoLink',{ref:"WidgetContacto",staticClass:"mb-6"}),_c(VRow,{staticClass:"mt-6",attrs:{"justify":"center"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VSpacer):_vm._e(),_c(VCol,{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-body-1 font-weight-medium mb-2"},[_vm._v("Total a pagar:")]),_c('div',{staticClass:"text-h3 primary--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total,'$ ', 0, { thousandsSeparator: '.' })))])])],1)],1):_c(VCardText,[_c('div',{staticClass:"text-body-1"},[_vm._v("Este link ya no está disponible")])]),_c(VCardActions,[_c(VSpacer),(!_vm.error)?_c(VBtn,{staticClass:"mx-3 mb-3 pagar_sf",attrs:{"color":"secondary","disabled":_vm.loadingCodigo,"loading":_vm.pagarLoading},on:{"click":_vm.pagar}},[_vm._v("ir a pagar"),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1):_c(VBtn,{staticClass:"mx-3 mb-3",attrs:{"color":"secondary","href":"/"}},[_vm._v("volver al inicio"),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-home")])],1)],1)],1):_c(VCard,[_c(VCardText,[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }