import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.error)?_c(VCard,[_c(VCardText,[(_vm.pasajeros > 0)?_c('WidgetPasajeros',{ref:"wPasajeros"}):_vm._e(),(_vm.vehiculos > 0)?_c('WidgetVehiculos',{ref:"wVehiculos",staticClass:"mt-3"}):_vm._e()],1),_c(VCardActions,[_c(VBtn,{staticClass:"mx-3 mb-3",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('prev')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Volver")],1),_c(VSpacer),_c(VBtn,{staticClass:"mx-3 mb-3",attrs:{"color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.validar()}}},[_vm._v("Continuar"),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1):_c(VCard,[_c(VCardTitle,[_vm._v("Ocurrió un error")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"my-3 body-1"},[_vm._v("Lo sentimos, no pudimos generar su reserva.")]),_c('div',{staticClass:"my-3 body-1"},[_vm._v("Por favor revise sus datos e intente nuevamente.")]),_c('div',{staticClass:"my-3 body-1"},[_vm._v("Si el problema persiste, comuníquese con su agencia más cercana.")])])],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"mx-3 mb-3",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('prev')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Volver")],1),_c(VSpacer)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }