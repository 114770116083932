<template lang="pug">
  v-card(hover color="primary" :min-height="height")
    div.d-flex.flex-no-wrap.justify-center(:style="'height: ' + height + 'px'")
      div.ml-3
        v-img.shrink.mt-12.ml-6(:src="bocadillo" :max-width="size_bocadillo")
        v-checkbox.ml-6.mt-7(v-if="!socio" v-model="inscribir" color="secondary" dark large)
          template(v-slot:label)
            div.text-body-1.text-sm-h6 Deseo participar en el Club de Socios
      v-avatar.ma-3(:size="size_australito")
        v-img.shrink(src="images/australito.png")
    div.mt-n16.secondary.white--text.d-flex.align-center(style="height: 80px")
      div
        v-icon.mx-6(x-large color="white") mdi-ferry
      div
        div.font-italic.text-md-h6.text-body-1 Suma millas náuticas. Canjea beneficios.
    v-row.mt-6(align="center" justify="center" dense)
      v-col(cols="auto")
        v-text-field.ml-3(label="Código Promocional" v-model="codigo" outlined prepend-icon="mdi-ticket" @blur="onBlur" :loading="loading" :error="error" :error-messages="errorMsg" :success="success" :success-messages="successMsg" max="20" v-mask="'XXXXXXXXXXXXXXXXXXXX'" append-outer-icon="mdi-send" @click:append-outer="onBlur" :readonly="success" clearable @click:clear="limpiar" color="white" dark)

</template>

<script>

export default {
  name: 'WidgetSocios',

  components: {
  },

  data: () => ({
    codigo: '',
    errorMsg: '',
    error: false,
    successMsg: '',
    success: false,
    resultado: false
  }),

  computed: {
    loading () {
      return this.$store.state.codigoLoading
    },

    index () {
      return this.$store.state.indexContacto || 0
    },

    socio () {
      return this.$store.state.pasajerosArr[this.$store.state.indexContacto]?.socio || false
    },

    height () {
      if (this.socio) {
        if (this.$vuetify.breakpoint.xs) {
          return '200'
        }
        return '250'
      }
      return '300'
    },

    inscribir: {
      get () {
        return this.$store.state.inscribir_socio
      },

      set (val) {
        this.$store.commit('SET_INSCRIBIR_SOCIO', val)
      }
    },

    size_australito () {
      if (this.$vuetify.breakpoint.xs) {
        return 110
      }
      if (this.$vuetify.breakpoint.sm) {
        return 180
      }
      if (this.$vuetify.breakpoint.md) {
        return 200
      }
      if (this.$vuetify.breakpoint.lg) {
        return 250
      }
      return 300
    },

    bocadillo () {
      if (this.socio) {
        return 'images/bocadillo2.png'
      }

      return 'images/bocadillo.png'
    },

    size_bocadillo () {
      if (this.$vuetify.breakpoint.xs) {
        return 170
      }
      if (this.$vuetify.breakpoint.sm) {
        return 220
      }
      if (this.$vuetify.breakpoint.md) {
        return 250
      }
      return 300
    }
  },

  watch: {
    codigo () {
      if (this.resultado) {
        this.limpiar()
      }
    },

    index () {
      this.$store.commit('SET_INSCRIBIR_SOCIO', false)
    }
  },

  methods: {
    async onBlur () {
      this.codigo = this.codigo?.trim()
      if (!this.codigo || this.codigo === '') {
        return
      }
      this.codigo = this.codigo.toUpperCase()
      this.error = false
      this.errorMsg = ''
      this.success = false
      this.successMsg = ''
      const res = await this.$store.dispatch('validateCode', this.codigo).catch(() => {
        this.$store.dispatch('notifyErrorServer')
      })
      this.resultado = true
      switch(res) {
        case true:
          this.successMsg = 'Código válido'
          this.success = true
          break
        case false:
          this.errorMsg = 'Código no vigente'
          this.error = true
          break
        default:
          this.errorMsg = 'Código no válido'
          this.error = true
          break
      }
    },

    limpiar () {
      this.$store.commit('SET_CODIGO', undefined)
      this.error = false
      this.errorMsg = ''
      this.success = false
      this.successMsg = ''
      this.resultado = false
    }
  }
}

</script>

<style type="text/css">
  .fondosocios {
    background-color: #00a5ab;
  }
</style>
