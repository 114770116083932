<template lang="pug">
  v-card
    v-card-text
      v-tabs(v-if="tipo_viaje === 'vuelta'" v-model="tab" color="secondary" slider-color="primary" slider-size="4")
        v-tab(v-for="(trayecto, t) in itinerarios", :key="t")
          | {{ tipo(t) }}
          v-badge.ml-2(v-if="errores[t]" color="error" dot)
      v-tabs-items(v-model="tab")
        v-tab-item(v-for="(trayecto, t) in itinerarios", :key="t")
          div.my-6.text-h6.font-weight-light(v-if="trayecto === undefined || trayecto.length === 0")
            div No existen viajes disponibles o viajes con disponibilidad de metros lineales (vehículos) para la ruta <b>{{ ruta(t) }}</b> en la fecha seleccionada.
            div.mt-3 Escoja otros puertos de origen y destino u otras fechas de viaje.
          div.my-6.text-h6.font-weight-light(v-else) Elija un viaje de <b>{{ tipo(t) }}</b>:
          v-item-group(v-model="seleccionado[tab]")
            v-item(v-for="(itinerario, i) in trayecto", :key="i" v-slot="{ active, toggle }" active-class="it-borde-act")
              v-card.it-borde-sec(@click="toggle" outlined :disabled="disponibilidad(itinerario)")
                v-card-text
                  div.text-h6.text-center {{ $moment(itinerario.Fecha).format('dddd LL') }}
                  div.pt-3.d-flex.justify-space-around.text-center.text-caption.text-sm-body-1.px-lg-16
                    div.font-weight-medium(style="width: 120px;")
                      v-icon(color="secondary" large) mdi-ferry
                      div {{ $moment(itinerario.Fecha).format('DD/MM') }}
                      div {{ $moment(itinerario.Fecha).format('HH:mm A') }}
                      div {{ origen }}
                    // div
                    div(style="width: 100%; text-align: -webkit-center;")
                      div.linea.mt-8
                      div.text-caption.flotar.px-2 Duración
                      div.text-caption.text-sm-body-1.font-weight-medium.flotar.px-2 {{ duracion(itinerario.Duracion) }}
                    // div
                    div.font-weight-medium(style="width: 120px;")
                      v-icon(color="secondary" large) mdi-map-marker
                      div {{ $moment(itinerario.Fecha).add(itinerario.Duracion, 'minutes').format('DD/MM') }}
                      div {{ $moment(itinerario.Fecha).add(itinerario.Duracion, 'minutes').format('HH:mm A') }}
                      div {{ destino }}
                  div.pb-2.text-center.text-body-1.primary--text.font-weight-medium {{ escalas(itinerario) }}
                  div.text-center.text-caption.primary--text Disponibilidad
                  div.d-flex.primary--text.justify-center.align-center
                    v-chip.mx-3(color="primary" outlined)
                      v-icon(left) mdi-seat-recline-extra
                      | {{ itinerario.Personas }}
                    v-chip(color="primary" outlined)
                      v-icon(left) mdi-car
                      | {{ itinerario.Vehiculos }}
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="capacidadLoading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3(color="secondary" @click="validar", :loading="capacidadLoading")
        | Continuar
        v-icon(right) mdi-chevron-right
    v-card-text(v-if="sin_viaje")
      v-card(@click="$emit('prev')")
        v-img(src="../../assets/images/banner.png")
</template>

<script>

export default {
  name: 'Itinerarios',

  props: {
    value: {
    }
  },

  data: () => ({
    seleccionado: [null, null],
    tab: 0,
    errores: [false, false]
  }),

  watch: {
    itinerarios (val) {
      if (val === null) {
        this.seleccionado = [null, null]
      }
    },

    seleccionado: {
      deep: true,
      handler (val) {
        if (val === undefined) {
          this.$store.commit('SET_ITINERARIO', [null, null])
          return
        } else {
          let it = []
          for (let i = 0; i < val.length; i++) {
            if (val[i] !== null && val[i] !== undefined) {
              it.push(this.itinerarios[i][val[i]])
              this.it_error(val[i], false)
            } else {
              it.push(null)
            }
          }
          this.$store.commit('SET_ITINERARIO', it)
          // this.$store.commit('SET_PRERESERVA', [null, null])
          this.$store.commit('LIMPIAR_BUTACAS')
        }
        this.$forceUpdate()
      }
    }
  },

  computed: {
    origen () {
      if (this.tab === 0) {
        return this.$store.state.origen
      } else {
        return this.$store.state.destino
      }
    },

    destino () {
      if (this.tab === 0) {
        return this.$store.state.destino
      } else {
        return this.$store.state.origen
      }
    },

    itinerarios () {
      return this.$store.state.itinerarios
    },

    itinerario () {
      return this.$store.state.itinerario
    },

    capacidadLoading () {
      return this.$store.state.capacidadLoading
    },

    deshabilitar () {
      let count = 0
      for (let i = 0; i < this.itinerario.length; i++) {
        if (this.itinerario[i] !== null && this.itinerario[i] !== undefined) {
          count++
          this.it_error(i, false)
          continue
        }
        this.it_error(i, true)
      }

      return count < this.itinerarios.length
    },

    tipo_viaje () {
      return this.$store.state.tipo_viaje
    },

    pasajeros () {
      return this.$store.state.pasajerosArr.length
    },

    vehiculos () {
      return this.$store.state.vehiculosArr.length
    },

    sin_viaje () {
      if (!this.itinerarios) {
        return true
      }

      if (this.tipo_viaje === 'vuelta') {
        if (!this.itinerarios[1] || this.itinerarios[1].length === 0) {
          return true
        }
      }

      if (!this.itinerarios[0] || this.itinerarios[0].length === 0) {
        return true
      }

      return false
    }
  },

  mounted () {
    this.setear()
  },

  methods: {
    it_error (it, flag) {
      this.errores[it] = flag
    },

    setear () {
      if (this.itinerario && this.itinerario.length > 0 && this.itinerarios && this.itinerarios.length > 0) {
        for (let j = 0; j < this.itinerario.length; j++) {
          if (!this.itinerario[j]) {
            continue
          }
          const Itinerario = this.itinerario[j].Itinerario
          for (let i = 0; i < this.itinerarios[j].length; i++) {
            if (Itinerario === this.itinerarios[j][i].Itinerario) {
              this.seleccionado[j] = i
              break
            }
          }
        }
      }
      this.$forceUpdate()
    },

    tipo (t) {
      if (t === 1) {
        return 'vuelta'
      } else {
        return 'ida'
      }
    },

    ruta (t) {
      if (t === 1) {
        return this.$store.state.destino + ' - ' + this.$store.state.origen
      }
      return this.$store.state.origen + ' - ' + this.$store.state.destino
    },

    escalas (it) {
      let escalas = parseInt(it.Escalas)
      switch (escalas) {
        case 0: return 'Sin escalas'
        case 1: return '1 escala'
        default: return escalas + ' escalas'
      }
    },

    duracion (dur) {
      const d = parseInt(dur)
      // let dias = parseInt(Math.floor(d / 1440))
      // let horas = parseInt(Math.floor(d / 60)) - dias
      let horas = parseInt(Math.floor(d / 60))
      let minutos = parseInt(Math.floor(d % 60))

      let horasTxt
      if (horas >= 2) {
        horasTxt = horas + ' horas'
      } else if (horas === 1) {
        horasTxt = horas + ' hora'
      }

      let minutosTxt
      if (minutos > 0) {
        minutosTxt = minutos + ' minutos'
      }

      let final
      if (horasTxt && minutosTxt) {
        final = horasTxt + ' y ' + minutosTxt
      } else if (horasTxt) {
        final = horasTxt
      } else if (minutosTxt) {
        final = minutosTxt
      } else {
        final = dur
      }

      return final
    },

    disponibilidad (it) {
      return this.vehiculos >= parseInt(it.Vehiculos) && this.pasajeros >= parseInt(it.Personas)
    },

    validar () {

      // Validar que estén todos los itinerarios seleccionados
      if (this.deshabilitar) {
        let txt = 'Seleccione el itinerario'
        if (this.tipo_viaje === 'vuelta') {
          txt = 'Seleccione los itinerarios de ida y de vuelta'
        }
        this.$store.dispatch('notify', {
          color: 'error',
          text: txt,
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
        this.$forceUpdate()
        return
      }

      // Validar que la vuelta sea posterior a la ida
      if (this.tipo_viaje === 'vuelta') {
        const ida = this.$moment(this.itinerario[0].Fecha)
        const vuelta = this.$moment(this.itinerario[1].Fecha)

        if (ida.isAfter(vuelta)) {
          this.$store.dispatch('notify', {
            color: 'error',
            text: 'El viaje de vuelta debe ser posterior a la ida',
            icon: 'mdi-alert-circle',
            timeout: 5000
          })
          return
        }
      }

      this.continuar()
    },

    async continuar () {
      this.$emit('next')
    }
  }
}

</script>

<style type="text/css">
.it-borde-sec {
  padding: 10px !important;
}

.it-borde-act {
  border-color: #003399 !important;
  border-width: medium !important;
  padding: 8px !important;
}

.linea {
  border-bottom: dashed;
  border-width: 1px;
}

.flotar {
  position: relative;
  top: -20px;
  width: fit-content;
  background: white;
}

</style>
