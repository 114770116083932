import Vue from 'vue';
import Vuetify, {
	VStepper,
	VCard,
	VBtn,
	VApp,
	VAppBar,
} from 'vuetify/lib';

Vue.use(Vuetify, {
	components: {
		VStepper,
		VCard,
		VBtn,
		VApp,
		VAppBar,
	}
});

export default new Vuetify({
	theme: {
    themes: {
      light: {
        primary: '#003399',
        primary35: '#6777B0',
        secondary: '#FF9900',
        accent: '#2A2122',
        gris: '#928F90',
      },
    },
  },
});
