import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"86px"}},[_c(VCard,{staticClass:"mb-2",class:_vm.error ? 'errorcustom' : '',attrs:{"id":"grupo","outlined":""}},[_c(VCardText,{staticClass:"px-3 py-2"},[_c(VRow,{class:_vm.error ? 'error--text' : '',attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"v-label",class:_vm.error ? 'tiene-error' : ''},[_vm._v(_vm._s(_vm.label))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":_vm.error ? 'error' : 'primary',"disabled":_vm.disabled},on:{"click":_vm.decrementar}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_c('div',{staticClass:"px-3 text-center font-weight-bold",staticStyle:{"width":"45px"}},[_vm._v(_vm._s(_vm.value))]),_c(VBtn,{attrs:{"icon":"","color":_vm.error ? 'error' : 'primary',"disabled":_vm.disabled},on:{"click":_vm.incrementar}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1)],1),_c(VExpandTransition,[(_vm.error)?_c('div',{staticClass:"text-field__details px-3"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v("Requerido")])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }