import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

axios.defaults.baseURL = 'https://api.navieraustral.cl/v2/'
const EXPIRACION_RESERVA = 20

Vue.use(Vuex)

//to handle state
const state = {
  EXPIRACION_RESERVA,
  session_id: null,
  paso: 0,
  tipo_viaje: 'ida',
  fecha_ida: '',
  fecha_regreso: '',
  origen: null,
  destino: null,
  origenes: null,
  destinos: null,
  marcas: null,
  marcasObj: {},
  nacionalidades: null,
  vehiculosArr: [],
  pasajerosArr: [],
  itinerarios: undefined,
  itinerario: [null, null],
  capacidad: [null, null],
  capacidadLoading: false,
  prereserva: [null, null],
  temp_prereserva: null,
  prereservaLoading: false,
  nominaPaxLoading: false,
  nominaVehLoading: false,
  tarifas: null,
  tarifasLoading: false,
  dte: 'ninguno',
  notify_flag: false,
  notify_text: '',
  notify_color: '',
  notify_icon: '',
  notify_timeout: -1,
  notify_progress: 0,
  notify_timer: undefined,
  datosFactura: {},
  datosContacto: {},
  indexContacto: 0,
  datosPagoLoading: false,
  timeover_timer: null,
  timeover_minutos: EXPIRACION_RESERVA,
  timeover_modal: false,
  timeover_ts: null,
  primera_reserva: true,
  codigo: undefined,
  codigoLoading: false,
  inscribir_socio: false
}

//to handle state
const getters = {}

//to handle actions
const actions = {
  async getSessionId({ commit }) {
    const response = await axios.get('generate_session_id?id=' + Math.random())
    commit('SET_SESSION_ID', response.data.session_id)
  },

  async getOrigenes({ commit, dispatch, state }) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const response = await axios.get('get_origenes')
    commit('SET_ORIGENES', response.data.origenes)
  },

  async getDestinos({ commit, dispatch, state }, origen) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const data = {
      params: {
        origen
      }
    }
    commit('SET_DESTINOS', null)
    const response = await axios.get('get_destinos', data)
    let destinos
    if (response && response.data && response.data.destinos) {
      destinos = response.data.destinos.reverse()
    } else {
      destinos = response.data.destinos
    }
    commit('SET_DESTINOS', destinos)
  },

  async getMarcas({ commit, dispatch, state }) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    if (state.marcas) {
      return state.marcas
    }
    const response = await axios.get('get_marcas')
    commit('SET_MARCAS', response.data.marcas)
    return response.data.marcas
  },

  async getModelos({ commit, dispatch, state }, marca) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const data = {
      params: {
        marca
      }
    }
    // Si ya existen los modelos, salir
    if (state.marcasObj[marca]) {
      return
    }

    const response = await axios.get('get_modelos', data)
    const d = {
      marca: marca,
      modelos: response.data.modelos
    }
    commit('SET_MODELOS', d)
  },

  async getNacionalidades({ commit, dispatch, state }) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    if (state.nacionalidades) {
      return state.nacionalidades
    }
    const response = await axios.get('get_nacionalidades')
    const nacionalidades = response.data.nacionalidades.reverse()
    commit('SET_NACIONALIDADES', nacionalidades)
    return nacionalidades
  },

  async getItinerarios({ commit, dispatch, state }, obj) {
    commit('SET_ITINERARIOS', null)
    commit('SET_ITINERARIO', [null, null])

    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const promesas = []
    let tipo = obj.tipo === 'ida' ? ['ida'] : ['ida', 'vuelta']
    for (let i = 0; i < tipo.length; i++) {
      let o
      let d
      if (tipo[i] === 'ida') {
        o = obj.origen
        d = obj.destino
      } else {
        d = obj.origen
        o = obj.destino
      }
      const data = {
        params: {
          ...obj.carga,
          f: obj['fecha_' + tipo[i]],
          o,
          d
        }
      }
      let p = axios.get('get_itinerarios', data)
      promesas.push(p)
    }
    const itinerarios = await Promise.all(promesas).then(r => {
      const it = []
      for (let i = 0; i < r.length; i++) {
        it.push(r[i].data.itinerarios)
      }
      return it
    })
    commit('SET_ITINERARIOS', itinerarios)
  },

  async getCapacidad({ commit, dispatch, state }, itinerario) {
    commit('SET_CAPACIDAD_LOADING', true)
    commit('SET_CAPACIDAD', [null, null])

    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const promesas = []

    for (let i = 0; i < itinerario.length; i++) {
      if (itinerario[i] === null) {
        continue
      }

      let origen, destino
      if (i === 0) {
        origen = state.origen
        destino = state.destino
      } else {
        origen = state.destino
        destino = state.origen
      }

      const data = {
        params: {
          itinerario: itinerario[i],
          origen,
          destino
        }
      }
      let p = axios.get('get_capacidad', data)
      promesas.push(p)
    }

    const capacidad = await Promise.all(promesas).then(r => {
      const cap = []
      for (let j = 0; j < r.length; j++) {
        cap.push(r[j].data.capacidad)
      }
      return cap
    })
    commit('SET_CAPACIDAD', capacidad)
    commit('SET_CAPACIDAD_LOADING', false)
  },

  async preReserva({ commit, dispatch, state }) {
    commit('SET_PRERESERVA_LOADING', true)
    commit('SET_PRERESERVA', [null, null])
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const arrayVehicles = []
    for (let v = 0; v < state.vehiculosArr.length; v++) {
      arrayVehicles.push(convertirVehiculoSF(state.vehiculosArr[v]))
    }

    const data = []
    for (let i = 0; i < state.itinerario.length; i++) {
      const it = state.itinerario[i]
      if (!it) {
        continue
      }

      const roundtrip = i === 0 ? 'Ida' : 'Vuelta'
      let departure
      let destination
      if (i === 0) {
        departure = state.origen
        destination = state.destino
      } else {
        departure = state.destino
        destination = state.origen
      }

      let reservation = ''
      if (state.temp_prereserva !== null && state.temp_prereserva[i] != null) {
        reservation = state.temp_prereserva[i].id
      }

      const seats = state.pasajerosArr.map( p => String(p[roundtrip.toLowerCase()].label) )
      const body = {
        reservation,
        itinerarie: it.Itinerario,
        roundtrip,
        people: state.pasajerosArr.length,
        departure,
        destination,
        tripDate: moment(it.FechaItinerario).format('YYYY-MM-DD HH:mm:ss'),
        seats,
        arrayVehicles
      }

      data.push(body)
    }

    const response = await axios.post('pre_reserva', data).then(r => {
      const pre = r.data.pre_reservas

      if (pre) {
        let total = pre.length
        let oks = 0
        for (let i = 0; i < pre.length; i++) {
          if (pre[i] && pre[i].estado === 'ok') {
            oks++
          }
        }

        if (state.primera_reserva && oks > 0) {
          state.primera_reserva = false
          dispatch('notify', {
            color: 'green',
            text: '¡Reserva creada! Tiene ' + EXPIRACION_RESERVA + ' minutos para terminar la compra.',
            icon: 'mdi-check',
            timeout: 10000
          })
          commit('INICIAR_TIMER', dispatch)
        }

        if (oks === total) {
          return pre
        }
        return false
      }
    })
    .catch(() => {
      return false
    })
    if (response === false) {
      commit('SET_PRERESERVA', null)
      commit('SET_PRERESERVA_LOADING', false)
      return false
    }

    commit('SET_PRERESERVA', response)
    commit('SET_TEMP_PRERESERVA', response)
    commit('SET_PRERESERVA_LOADING', false)
    return true
  },

  async getCliente({ commit, dispatch, state }, id) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    if (id === undefined || id === null || state.pasajerosArr.length === 0) {
      return false
    }

    if (id >= state.pasajerosArr.length || id < 0) {
      return false
    }

    const cliente = state.pasajerosArr[id].pDocNum

    const data = {
      params: {
        cliente
      }
    }
    state.pasajerosArr[id]['loading'] = true
    const response = await axios.get('get_cliente', data)
    const obj = {
      index: id,
      data: response.data.cliente
    }
    commit('ACTUALIZAR_PASAJERO', obj)
    return true
  },

  async getClienteLink({ dispatch, state }, cliente) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const data = {
      params: {
        cliente
      }
    }

    const response = await axios.get('get_cliente', data)

    return response.data.cliente
  },

  async nominar_pasajeros({ commit, dispatch, state }) {
    commit('SET_NOMINAR_PAX_LOADING', true)
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const reservas = []
    const asientos = []
    const pasajeros = []

    for (let i = 0; i < state.prereserva.length; i++) {
      const reserva = state.prereserva[i]
      if (!reserva) {
        continue
      }
      const roundtrip = i === 0 ? 'Ida' : 'Vuelta'

      reservas.push(reserva.id)

      const fila_asientos = []

      for (let p = 0; p < state.pasajerosArr.length; p++) {
        const pax = state.pasajerosArr[p]
        fila_asientos.push(String(pax[roundtrip.toLowerCase()].label))
        if (i === 0) {
          let pPhone = pax.pPhone
          if (pax.pDocType === 'Pasaporte' && pax.sin_telefono) {
            pPhone = '999999999'
          }
          const body = {
            pName: pax.pName,
            pLastname: pax.pLastname,
            pGender: pax.pGender,
            pBirthdate: pax.pBirthdate,
            pDocType: pax.pDocType,
            pDocNum: pax.pDocNum,
            // pNationality: pax.pNationality,
            pais: pax.pais,
            pPhone: '+56' + pPhone,
            pEmail: pax.pEmail,
            pIsDriver: pax.pIsDriver
          }
          pasajeros.push(body)
        }
      }

      asientos.push(fila_asientos)
    }

    const data = {
      reservas,
      asientos,
      pasajeros
    }

    const nominas = await axios.post('agregar_personas', data).catch(() => {
      commit('SET_NOMINAR_PAX_LOADING', false)
      return null
    })
    if (!nominas) {
      return
    }

    commit('SET_NOMINAR_PAX_LOADING', false)
    return nominas.data
  },

  async nominar_vehiculos({ commit, dispatch, state }) {
    commit('SET_NOMINAR_VEH_LOADING', true)
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const reservas = []
    const vehiculos = []

    for (let i = 0; i < state.prereserva.length; i++) {
      const reserva = state.prereserva[i]
      if (!reserva) {
        continue
      }

      reservas.push(reserva.id)
    }

    for (let v = 0; v < state.vehiculosArr.length; v++) {
      const veh = convertirVehiculoSF(state.vehiculosArr[v], true)
      /* const body = {
        ...veh,
        dispatcher: null,
        receiver: null
      } */
      vehiculos.push(veh)
    }

    const data = {
      reservas,
      vehiculos
    }

    const nominas = await axios.post('agregar_vehiculos', data).catch(() => {
      commit('SET_NOMINAR_VEH_LOADING', false)
      return null
    })
    if (!nominas) {
      return
    }

    commit('SET_NOMINAR_VEH_LOADING', false)
    return nominas.data
  },

  async getTarifas({ commit, dispatch, state }, data) {
    commit('SET_TARIFAS', null)
    commit('SET_TARIFAS_LOADING', true)

    const params = {
      params: {
        link: false
      }
    }

    if (data && data.id) {
      params.params.link = true
      params.params.id_reserva = data.id_reserva
      commit('SET_SESSION_ID', data.id)

      if (data.reserva) {
        params.params.reserva = data.reserva
      }
    }
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const response = await axios.get('get_tarifas', params.params.link ? params : undefined)
    commit('SET_TARIFAS', response.data.tarifas)
    commit('SET_TARIFAS_LOADING', false)
    return response.data.tarifas
  },

  async agregar_datos_pago({ commit, dispatch, state }) {
    commit('SET_DATOS_PAGO_LOADING', true)
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const body = {
      tipoDocumento: tipoDTE(state.dte),
      datosContacto: state.datosContacto,
      datosFactura: state.datosFactura,
      inscribir_socio: state.inscribir_socio
    }
    if (state.codigo) {
      body.codigo = state.codigo
    }
    const datos_pago = await axios.post('agregar_datos_pago', body).catch(() => {
      return null
    })
    commit('SET_DATOS_PAGO_LOADING', false)
    if (!datos_pago) {
      return
    }
    return datos_pago.data.datos
  },

  async validateCode({ commit, dispatch, state }, code) {
    commit('SET_CODIGO_LOADING', true)
    if (!state.session_id) {
      await dispatch('getSessionId')
    }
    const data = {
      params: {
        code
      }
    }

    commit('SET_CODIGO', undefined)

    const response = await axios.get('codigo/validate', data)
    if (response.data) {
      commit('SET_CODIGO', code)
    }
    commit('SET_CODIGO_LOADING', false)
    return response.data
  },

  async acreditar({ dispatch, state }, data) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    await axios.post('acreditar', data)
  },

  async rechazar({ dispatch, state }) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    await axios.post('rechazar')
  },

  async pagar({ dispatch, state }) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const pagar = axios.defaults.baseURL + 'pagar?session_id=' + state.session_id
    window.location.href = pagar
  },

  async getDatosLink({ dispatch, state }, datos) {
    if (!state.session_id) {
      await dispatch('getSessionId')
    }

    const data = {
      params: datos
    }

    const datos_link = await axios.get('datos_link', data).catch(() => {
      return null
    })

    return datos_link
  },

  notifyErrorServer ({ dispatch }) {
    const payload = {
      color: 'error',
      text: 'Hubo un error. Por favor intente nuevamente',
      icon: 'mdi-alert-circle',
      timeout: 7000
    }
    dispatch('notify', payload)
  },

  notify ({ commit }, payload) {
    commit('SET_NOTIFY', payload)
  },

  resetNotify ({ commit }) {
    commit('RESET_NOTIFY')
  },

  timeover ({ commit, state }) {
    state.timeover_modal = false
    state.timeover_minutos = EXPIRACION_RESERVA
    clearInterval(state.timeover_timer)
    state.timeover_timer = null
    state.timeover_ts = null
    state.primera_reserva = true

    commit('SET_TEMP_PRERESERVA', null)
    commit('SET_SESSION_ID', null)
    commit('SET_PRERESERVA', [null, null])
    commit('SET_ITINERARIO', [null, null])
    commit('SET_CAPACIDAD_LOADING', false)
    commit('SET_PRERESERVA_LOADING', false)
    commit('SET_NOMINAR_PAX_LOADING', false)
    commit('SET_NOMINAR_VEH_LOADING', false)
    commit('SET_TARIFAS_LOADING', false)
    commit('SET_DATOS_PAGO_LOADING', false)
    commit('SET_CODIGO', undefined)
    commit('SET_CODIGO_LOADING', false)
    commit('SET_INSCRIBIR_SOCIO', false)
    commit('RESET_NOTIFY')
    commit('SET_INDEX_CONTACTO', 0)
    commit('SET_PASO', 0)
  }
}

//to handle mutations
const mutations = {
  SET_SESSION_ID(state, session_id) {
    state.session_id = session_id
    if (session_id === null) {
      delete axios.defaults.headers.common.session_id
      return
    }
    axios.defaults.headers.common['session_id'] = session_id
  },

  SET_PASO(state, paso) {
    state.paso = paso
  },

  SET_TIPO_VIAJE(state, tipo_viaje) {
    state.tipo_viaje = tipo_viaje
  },

  SET_ORIGEN(state, origen) {
    state.origen = origen
  },

  SET_DESTINO(state, destino) {
    state.destino = destino
  },

  SET_FECHA_IDA(state, fecha_ida) {
    state.fecha_ida = fecha_ida
  },

  SET_FECHA_REGRESO(state, fecha_regreso) {
    state.fecha_regreso = fecha_regreso
  },

  SET_ORIGENES(state, origenes) {
    state.origenes = origenes
  },

  SET_DESTINOS(state, destinos) {
    state.destinos = destinos
  },

  SET_MARCAS(state, marcas) {
    state.marcas = marcas
    state.marcasObj = {}
    state.marcas.forEach( el => {
      state.marcasObj[el] = null
    })
  },

  SET_MARCA(state, data) {
    if (!data) {
      return
    }
    let n = data.n
    if (state.vehiculosArr.length < n) {
      return
    }
    state.vehiculosArr[n].marca = data.marca
  },

  SET_MODELOS(state, data) {
    if (!data) {
      return
    }
    state.marcasObj[data.marca] = data.modelos
  },

  SET_NACIONALIDADES(state, nac) {
    state.nacionalidades = nac
  },

  AGREGAR_VEHICULO(state) {
    const data = {
      tipo: 'menor',
      subtipo: 'motorhome',
      tag: short_uuid(),
      marca: null,
      modelo: null,
      pe: false,
      pm: false,
      ca: false,
      ancho: undefined,
      largo: undefined,
      alto: undefined,
      largo_carro: undefined,
      extra: undefined
    }
    state.vehiculosArr.push(data)
  },

  AGREGAR_PASAJERO(state) {
    const data = {
      pDocType: 'RUT',
      pPhone: '',
      disabled: true,
      loading: false,
      buscado: false
    }
    state.pasajerosArr.push(data)
  },

  LIMPIAR_BUTACAS(state, tipo) {
    for (let p = 0; p < state.pasajerosArr.length; p++) {
      switch (tipo) {
        case 'ida':
        case 'vuelta':
          delete state.pasajerosArr[p][tipo]
          break
        default:
          delete state.pasajerosArr[p]['ida']
          delete state.pasajerosArr[p]['vuelta']
          break
      }
    }
  },

  SACAR(state, tipo) {
    if (tipo === 'pasajeros') {
      state.pasajerosArr.pop()
      return
    }
    if (tipo === 'vehiculos') {
      state.vehiculosArr.pop()
      return
    }
  },

  SET_ITINERARIOS(state, itinerarios) {
    state.itinerarios = itinerarios
  },

  SET_ITINERARIO(state, itinerario) {
    state.itinerario = itinerario
  },

  SET_CAPACIDAD(state, capacidad) {
    state.capacidad = capacidad
  },

  SET_CAPACIDAD_LOADING(state, capacidadLoading) {
    state.capacidadLoading = capacidadLoading
  },

  SET_PRERESERVA(state, prereserva) {
    state.prereserva = prereserva
  },

  SET_TEMP_PRERESERVA(state, prereserva) {
    state.temp_prereserva = prereserva
  },

  SET_PRERESERVA_LOADING(state, prereservaLoading) {
    state.prereservaLoading = prereservaLoading
  },

  SET_NOMINAR_PAX_LOADING(state, loading) {
    state.nominaPaxLoading = loading
  },

  SET_NOMINAR_VEH_LOADING(state, loading) {
    state.nominaVehLoading = loading
  },

  SET_TARIFAS(state, tarifas) {
    let pax_valido = 0
    let veh_valido = 0
    if (tarifas && tarifas.reserva) {
      const tarifasP = tarifas.reserva.pasajeros
      for (let p = 0; p < tarifasP.length; p++) {
        const tPax = tarifasP[p]
        const pax = state.pasajerosArr.find(p => p.pDocNum === tPax.identificacion)
        if (!pax) {
          continue
        }
        const tipo = tPax.tipoReserva.toLowerCase()
        if (!pax[tipo]) {
          continue
        }
        pax[tipo].precio = parseFloat(tPax.pasaje)
        pax[tipo].traslado = parseFloat(tPax.traslado)
        pax_valido++
      }

      const tarifasV = tarifas.reserva.vehiculos
      for (let v = 0; v < tarifasV.length; v++) {
        const tVeh = tarifasV[v]
        const veh = state.vehiculosArr.find(v => v.patente === tVeh.patente)
        if (!veh) {
          continue
        }
        if (!veh.ida) {
          veh.ida = {}
        }
        if (!veh.vuelta) {
          veh.vuelta = {}
        }
        const tipo = tVeh.tipoReserva.toLowerCase()
        veh[tipo].precio = parseFloat(tVeh.pasaje)
        veh[tipo].traslado = parseFloat(tVeh.traslado)
        veh_valido++
      }
    }

    if (tarifas) {
      if ((state.tipo_viaje === 'ida' && (pax_valido !== state.pasajerosArr.length || veh_valido !== state.vehiculosArr.length)) ||
          (state.tipo_viaje === 'vuelta' && (pax_valido !== state.pasajerosArr.length*2 || veh_valido !== state.vehiculosArr.length*2))) {
        tarifas.error_tarifa = true
      }
    }
    state.tarifas = tarifas
  },

  SET_TARIFAS_LOADING(state, tarifasLoading) {
    state.tarifasLoading = tarifasLoading
  },

  SET_DTE(state, dte) {
    state.dte = dte
  },

  SET_DATOS_FACTURA(state, datos) {
    state.datosFactura = datos
  },

  SET_INDEX_CONTACTO(state, datos) {
    state.indexContacto = datos
  },

  SET_DATOS_CONTACTO(state, datos) {
    state.datosContacto = datos
  },

  SET_DATOS_PAGO_LOADING(state, loading) {
    state.datosPagoLoading = loading
  },

  SET_CODIGO_LOADING(state, codigoLoading) {
    state.codigoLoading = codigoLoading
  },

  SET_PASAJERO(state, data) {
    const index = data.index
    const tipo = data.tipo
    if (index >= 0 && index < state.pasajerosArr.length) {
      state.pasajerosArr[index][tipo] = {...state.pasajerosArr[index][tipo], ...data.data}
    }
  },

  ACTUALIZAR_PASAJERO(state, obj) {
    const index = obj.index
    const data = obj.data
    const nacimiento = (data['FechaNacimiento'] || '').split('T')[0]
    state.pasajerosArr[index]['pName'] = data['Nombre'] || null
    state.pasajerosArr[index]['pLastname'] = data['Apellido'] || null
    state.pasajerosArr[index]['pGender'] = data['Genero'] || null
    // state.pasajerosArr[index]['pNationality'] = data['Nacionalidad'] || null
    state.pasajerosArr[index]['pais'] = data['Pais'] || null
    state.pasajerosArr[index]['pBirthdate'] = nacimiento || null
    state.pasajerosArr[index]['disabled'] = false
    state.pasajerosArr[index]['loading'] = false
    state.pasajerosArr[index]['buscado'] = true
    state.pasajerosArr[index]['socio'] = data.socio
    state.pasajerosArr[index]['error'] = !state.pasajerosArr[index].pDocNum || !data['Nombre'] || !data['Apellido'] || !data['Genero'] || !nacimiento
  },

  LIMPIAR_PASAJERO(state, id) {
    const pDocType = state.pasajerosArr[id].pDocType
    const ida = state.pasajerosArr[id].ida
    const vuelta = state.pasajerosArr[id].vuelta
    state.pasajerosArr[id] = {
      pDocType,
      ida,
      vuelta,
      disabled: true,
      loading: false,
      buscado: false
    }
  },

  RESETEAR_CONDUCTORES (state) {
    for(let p = 0; p < state.pasajerosArr.length; p++) {
      state.pasajerosArr[p].pIsDriver = false
    }
  },

  INICIAR_TIMER (state, dispatch) {
    state.timeover_ts = moment()
    clearInterval(state.timeover_timer)
    state.timeover_timer = setInterval(() => {
      const diff = moment().diff(state.timeover_ts, 'minutes')
      if (diff > EXPIRACION_RESERVA) {
        state.timeover_minutos = 0
        state.timeover_modal = true
        clearInterval(state.timeover_timer)
        state.timeover_timer = null
        return
      }
      state.timeover_minutos -= 1

      let fraccion = parseInt(EXPIRACION_RESERVA/4)
      if (state.timeover_minutos === fraccion) {
        dispatch('notify', {
          color: 'secondary',
          text: 'Quedan ' + fraccion + ' minutos para que termine su reserva',
          icon: 'mdi-timer-alert',
          timeout: 10000
        })
        return
      }

      if (state.timeover_minutos === 1) {
        dispatch('notify', {
          color: 'error',
          text: 'Queda menos de 1 minuto para que termine su reserva',
          icon: 'mdi-timer-alert',
          timeout: 10000
        })
        return
      }

      if (state.timeover_minutos === 0) {
        dispatch('resetNotify')
        state.timeover_modal = true
        clearInterval(state.timeover_timer)
        state.timeover_timer = null
        return
      }
    }, 60000)
  },

  SET_NOTIFY (state, payload) {
    state.notify_color = payload.color || state.notify_color
    state.notify_text = payload.text || state.notify_text
    state.notify_icon = payload.icon || state.notify_icon
    state.notify_flag = true
    state.notify_timeout = payload.timeout > 0 ? payload.timeout : -1
    state.notify_progress = 0
    if (payload.timeout > 0) {
      clearInterval(state.notify_timer)
      state.notify_timer = setInterval(() => {
        state.notify_progress++
        if (state.notify_progress >= 100) {
          clearInterval(state.notify_timer)
          state.notify_progress = 0
        }
      }, state.notify_timeout / 100)
    } else {
      state.notify_timeout = -1
    }
  },

  SET_CODIGO (state, codigo) {
    state.codigo = codigo
  },

  SET_INSCRIBIR_SOCIO (state, flag) {
    state.inscribir_socio = flag
  },

  RESET_NOTIFY (state) {
    state.notify_flag = false
    state.notify_color = 'success'
    state.notify_text = ''
    state.notify_icon = 'mdi-check'
    state.notify_timeout = -1
    state.notify_progress = 0
    clearInterval(state.notify_timer)
    state.notify_timer = undefined
  },
}

function convertirVehiculoSF (veh, full) {
  const vehSF = {
    licensePlate: veh.patente || '',
    tag: veh.tag,
    category: categoria(veh.tipo),
    brand: veh.marca || '',
    model: veh.modelo || '',
    luggageRack: veh.pe || false,
    bikeRack: veh.pm || false,
    pullingCart: veh.ca || false,
    pullingLong: veh.largo_carro || 0,
    vehicleType: subtipo(veh.subtipo) || '',
    vHigh: veh.alto || 0,
    vWidth: veh.ancho || 0,
    vLong: veh.largo || 0,
    tare: 0,
    load: 0,
  }

  if (full && veh.driver) {
    let pPhone = veh.driver.pPhone
    if (veh.driver.pDocType === 'Pasaporte' && veh.driver.sin_telefono) {
      pPhone = '999999999'
    }
    vehSF.driver = {
      pName: veh.driver.pName,
      pLastname: veh.driver.pLastname,
      pGender: veh.driver.pGender,
      pBirthdate: veh.driver.pBirthdate,
      pDocType: veh.driver.pDocType,
      pDocNum: veh.driver.pDocNum,
      pais: veh.driver.pais,
      pEmail: veh.driver.pEmail,
      pPhone: '+56' + pPhone,
      pIsDriver: true
    }
  }

  return vehSF
}

function categoria (tipo) {
  switch (tipo) {
    case 'menor':
      return 'Vehículo Menor'
    case 'bus':
      return 'Motorhome'
    case 'moto':
      return 'Motocicleta'
    case 'bici':
      return 'Bicicleta'
  }
  return ''
}

function subtipo (vehiculo) {
  if (vehiculo.tipo === 'bus') {
    switch (vehiculo.subtipo) {
      case 'motorhome':
        return 'CASA RODANTE'
      case 'minibus':
        return 'MINI BUS'
      case 'bus':
        return 'BUS'
    }
  }
  return undefined
}

function tipoDTE (dte) {
  switch (dte) {
    case 'boleta':
      return 'Boleta'
    case 'mixta':
      return 'Factura mixta'
    case 'exenta':
      return 'Factura exenta'
  }
  return 'Ninguno'
}

function short_uuid () {
  return uuidv4().split('-')[0]
}


//export store module
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
