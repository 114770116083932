<template lang="pug">
  v-slide-x-reverse-transition
    v-card(v-if="!oculto")
      v-card-title
        div Reserva
        v-spacer
        div(v-if="tiene_reserva")
          v-tooltip(v-for="(r, i) in reservas" :key="r" top)
            template(v-slot:activator="{ on, attrs }")
              v-chip.mr-1(:color="color" v-bind="attrs" v-on="on" dark) {{ r }}
            span Reserva de {{ tipo(i) }}
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-progress-circular.ml-1(size="30" :color="color" :value="porcentaje" rotate="-90" v-bind="attrs" v-on="on")
                div.text-caption {{ minutos }}
            span Minutos restantes
      v-card-text
        v-row(dense)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.origen")
              v-text-field(filled rounded dense hide-details label="Origen" v-model="$store.state.origen" readonly)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.destino")
              v-text-field(filled rounded dense hide-details label="Destino" v-model="$store.state.destino" readonly)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.fecha_ida")
              v-text-field(filled rounded dense hide-details label="Fecha de ida" v-model="ida" readonly)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.tipo_viaje === 'vuelta' && $store.state.fecha_regreso")
              v-text-field(filled rounded dense hide-details label="Fecha de regreso" v-model="regreso" readonly)
          v-col(cols="6")
            v-text-field(filled rounded dense hide-details label="Pasajeros", :value="total('pasajeros')" readonly)
          v-col(cols="6")
            v-text-field(filled rounded dense hide-details label="Vehículos", :value="total('vehículos')" readonly)
        v-row(dense)
          v-spacer
          v-col(cols="auto")
            v-expand-transition
              v-btn(text rounded color="primary" v-if="$route.path !== '/'" @click="editar")
                v-icon(left) mdi-pencil
                | Editar
</template>

<script>

export default {
  name: 'Resumen',

  components: {
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    oculto () {
      const oculto = !this.$store.state.destino
            && !this.$store.state.origen
            // && this.$store.state.fecha_ida === ''
            && this.$store.state.fecha_regreso === ''
            && this.$store.state.pasajerosArr.length === 0
            && this.$store.state.vehiculosArr.length === 0
      if (!oculto) {
        this.$emit('mostrar')
      }
      return oculto
    },

    ida () {
      if (this.$store.state.itinerario && this.$store.state.itinerario[0]) {
        return this.$moment(this.$store.state.itinerario[0].Fecha).format('YYYY-MM-DD, HH:mm A')
      }
      return this.$store.state.fecha_ida
    },

    regreso () {
      if (this.$store.state.itinerario && this.$store.state.itinerario[1]) {
        return this.$moment(this.$store.state.itinerario[1].Fecha).format('YYYY-MM-DD, HH:mm A')
      }
      return this.$store.state.fecha_regreso
    },

    color () {
      if (this.porcentaje >= 50) {
        return 'green'
      }
      if (this.porcentaje >= 30) {
        return 'secondary'
      }
      return 'error'
    },

    minutos () {
      return this.$store.state.timeover_minutos
    },

    porcentaje () {
      return this.minutos*100/this.$store.state.EXPIRACION_RESERVA
    },

    tiene_reserva () {
      return this.$store.state.temp_prereserva !== null
    },

    reservas () {
      if (!this.$store.state.temp_prereserva) {
        return []
      }
      return this.$store.state.temp_prereserva.map(r => 'R-' + parseInt(r.nombre.substr(2)))
    }
  },

  methods: {
    editar () {
      this.$emit('cerrar')
      this.$router.push('/')
    },

    total (tipo) {
      let n
      if (tipo === 'pasajeros') {
        n = this.$store.state.pasajerosArr.length
      }
      if (tipo === 'vehículos') {
        n = this.$store.state.vehiculosArr.length
      }

      if (n > 0) {
        return n
      }

      return 'Sin ' + tipo
    },

    tipo (i) {
      if (i === 1) {
        return 'vuelta'
      }
      return 'ida'
    }
  }
}

</script>

<style type="text/css">

</style>
