import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFooter,{staticClass:"primary"},[_c(VContainer,{staticClass:"white--text"},[_c(VRow,{staticClass:"text-center",attrs:{"row":"","wrap":"","align":"center","justify":"space-around"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VIcon,{attrs:{"color":"white","large":""}},[_vm._v("mdi-phone")]),_c('div',{staticClass:"text-h6"},[_c('a',{staticClass:"white--text text-decoration-none",attrs:{"href":"tel:6004019000"}},[_vm._v("600 401 9000")])]),_c('div',{staticClass:"text-caption"},[_vm._v("Horario de atención")]),_c('div',{staticClass:"text-body-2"},[_vm._v("Lunes a viernes de 09:00 a 22:00")]),_c('div',{staticClass:"text-body-2"},[_vm._v("Sábado y domingo de 10:00 a 22:00")])],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('a',{attrs:{"href":"https://navieraustral.cl"}},[_c(VImg,{staticClass:"shrink",attrs:{"alt":"Logo","contain":"","src":"images/logo.svg","height":"60"}})],1),_c('div',{staticClass:"text-caption"},[_vm._v("Todos los derechos reservados")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }