<template lang="pug">
  div(style="height: 86px")
    v-card.mb-2#grupo(outlined v-bind:class="error ? 'errorcustom' : ''")
      v-card-text.px-3.py-2
        v-row(no-gutters align="center" v-bind:class="error ? 'error--text' : ''")
          div.v-label(v-bind:class="error ? 'tiene-error' : ''") {{ label }}
          v-spacer
          v-btn(icon :color="error ? 'error' : 'primary'" @click="decrementar", :disabled="disabled")
            v-icon mdi-minus
          div.px-3.text-center.font-weight-bold(style="width: 45px") {{ value }}
          v-btn(icon :color="error ? 'error' : 'primary'" @click="incrementar", :disabled="disabled")
            v-icon mdi-plus
    v-expand-transition
      div.text-field__details.px-3(v-if="error")
        div.v-messages.theme--light.error--text(role="alert")
          div.v-messages__wrapper
            div.v-messages__message Requerido
</template>

<script>

export default {
  name: 'NumericInput',

  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    max: {
      type: [String, Number],
      default: 99
    },
    min: {
      type: [String, Number],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
  }),

  methods: {
    incrementar () {
      if (this.value < this.max) {
        this.$emit('incrementar')
      }
    },

    decrementar () {
      if (this.value > this.min) {
        this.$emit('decrementar')
      }
    }
  }
}

</script>

<style type="text/css">

.theme--light.v-sheet--outlined#grupo {
  border: thin solid #9e9e9e;
}

.theme--light.v-sheet--outlined.errorcustom#grupo  {
  border: 2px solid #ff5252;
}

.tiene-error {
  animation: v-shake 0.6s;
}

</style>
