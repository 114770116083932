<template lang="pug">
  LinkPago
</template>

<script>

import LinkPago from '@/components/UI/LinkPago'

export default {
  name: 'PagoComponent',

  components: {
    LinkPago
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
  }
}

</script>

<style type="text/css">

</style>
