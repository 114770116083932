import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tarifas && _vm.tarifas.reserva)?_c(VCard,[_c(VCardText,{staticClass:"text-center justify-center"},[_c(VIcon,{attrs:{"color":"secondary","large":""}},[_vm._v("mdi-card-account-details")]),_c('div',{staticClass:"text-body-1 font-weight-medium mb-3"},[_vm._v("Resumen Reserva "+_vm._s(_vm.nombre_reserva))]),_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.tarifas.reserva.pasajeros),function(pax,p){return _c(VCol,{key:p,attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Pasajero "+_vm._s(p + 1))]),_c(VCardText,[_c('div',[_c('b',[_vm._v("Nombre")]),_vm._v(": "+_vm._s(_vm._f("capitalize")(pax.nombre)))]),_c('div',[_c('b',[_vm._v("RUT")]),_vm._v(": "+_vm._s(_vm.rutificar(pax.identificacion)))]),_c('div',[_c('b',[_vm._v("Butaca")]),_vm._v(": "+_vm._s(pax.numero || 'S/A'))]),_c('div',[_c('b',[_vm._v("Precio")]),_vm._v(": "+_vm._s(_vm._f("currency")(pax.pasaje,'$ ', 0, { thousandsSeparator: '.' })))])])],1)],1)}),1),_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.tarifas.reserva.vehiculos),function(veh,v){return _c(VCol,{key:v,attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Vehículo "+_vm._s(v + 1))]),_c(VCardText,[_c('div',[_c('b',[_vm._v("Patente:")]),_vm._v(" "+_vm._s(_vm.patente(veh.patente)))]),_c('div',[_c('b',[_vm._v("Categoría:")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(veh.categoria)))]),_c('div',[_c('b',[_vm._v("Tipo:")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(veh.tipo)))]),_c('div',[_c('b',[_vm._v("Largo:")]),_vm._v(" "+_vm._s(veh.metros)+" metros")]),_c('div',[_c('b',[_vm._v("Precio:")]),_vm._v(" "+_vm._s(_vm._f("currency")(veh.pasaje,'$ ', 0, { thousandsSeparator: '.' })))])])],1)],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }